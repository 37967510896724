import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
 * {
   margin: 0;
   font-size: 0.875rem;
   padding: 0;
   font-family: 'Montserrat', sans-serif;
   line-height: 1.2;
   box-sizing: border-box;
 }
 
 html, body, div, p, header, footer, main, section, nav, ul, li, a, button, input, img, article, h1, h2, h3, h4, h5, h6 {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   list-style-type: none;
   text-decoration: none;
   border: none;
}
 
 :root {
   --bg: #0D1826;
   --bg-secondary: #10233A;
   --bg-blue: #F1F6FF;
   --text-color: #2d2d2d;
   --text-color-secondary: #FFFFFF;
   --highlight-blue: #112EF7;
   --highlight-yellow: #F7C82A;
   --max-width: 1140px;
}
 
 .subtitle{
   color: #007aff;
   font-size: 1.4rem;
 }
 
 body {
   background: #fff;
 }
 
 html {
   -webkit-font-smoothing: antialiased;
   -webkit-tap-highlight-color: transparent;
   scroll-behavior: smooth;
 }
 
 body, a, input {
   font-family: "Montserrat", sans-serif;
   color: var(--text-color);
 }
 
 h1, h2, h3, h4, h5, h6 {
   font-family: "JetBrains Mono", monospace;
 }
 
 p {
   line-height: 1.4;
 }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const Checkbox = styled.div`
  margin: 0.625rem 3.125rem 0;

  label {
    line-height: 1.25rem;

    a {
      color: #337ab7;
      text-decoration: none;
    }

    a:hover {
      color: #23527c;
      text-decoration: underline;
    }
  }
  input {
    margin-right: 5px;
  }

  @media (min-width: 280px) {
    margin-left: 0.625rem;
    width: 100%;
  }
`;

export const Error = styled.span`
  color: #f00;
  text-align: center;
  margin: 1.25rem auto 0.625rem;
  display: block;
`;
