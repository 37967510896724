import type { AppProps } from 'next/app';
import Global from '../styles/global';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import Provider from '../hooks';

export default function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NB5L2PM' });
  }, []);

  return (
    <Provider>
      <Component {...pageProps} />
      <Global />
    </Provider>
  );
}
