import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Contact } from '@/models/contact';
import { useSearchParams } from 'next/navigation';

type ContactProps = {
  contact: Contact;
  setContact: (props: any) => void;
};

const ContactContext = createContext<ContactProps>({} as ContactProps);

type ContactProviderProps = {
  children: ReactNode;
};

export const ContactProvider = ({ children }: ContactProviderProps) => {
  const searchParams = useSearchParams();
  const [contact, setContact] = useState<Contact>({
    email: '',
    availability: 'Pending answer',
    required_equipment: 'Pending answer',
  });

  useEffect(() => {
    const params = {
      utm_source: searchParams.get('utm_source') ?? 'no-tracking',
      utm_medium: searchParams.get('utm_medium') ?? 'no-tracking',
      utm_campaign: searchParams.get('utm_campaign') ?? 'no-tracking',
      utm_content: searchParams.get('utm_content') ?? 'no-tracking',
      utm_term: searchParams.get('utm_term') ?? 'no-tracking',
      hsa_ad: searchParams.get('hsa_ad') ?? 'no-tracking',
      hsa_grp: searchParams.get('hsa_grp') ?? 'no-tracking',
      hsa_cam: searchParams.get('hsa_cam') ?? 'no-tracking',
      hsa_src: searchParams.get('hsa_src') ?? 'no-tracking',
      origin_release: searchParams.get('origem_lancamento') ?? 'no-tracking',
      campaign_release: searchParams.get('campanha_lancamento') ?? 'no-tracking',
      indicator_email: searchParams.get('EMAIL_DO_INDICADOR') ?? 'no-tracking',
      indicator_name: searchParams.get('NOME_DO_INDICADOR') ?? 'no-tracking',
      indicator: searchParams.get('utm_indicator') ?? 'no-tracking',
      referral: searchParams.get('utm_referral') ?? 'no-tracking',
      recommendation_id: searchParams.get('utm_recommendation_id') ?? 'no-tracking',
      gclid: searchParams.get('gclid') ?? 'no-tracking',
      transaction_id: `${Date.now()}${Math.random().toString(36).substring(2)}`.slice(0, 20),
    };

    setContact({ ...contact, ...params });
  }, [searchParams]);

  return <ContactContext.Provider value={{ setContact, contact }}>{children}</ContactContext.Provider>;
};

export const useContact = () => useContext(ContactContext);
