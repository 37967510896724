import { ReactNode } from 'react';
import { ContactProvider } from './Contact';

type ProviderProps = {
  children: ReactNode;
};

const Provider = ({ children }: ProviderProps) => {
  return <ContactProvider>{children}</ContactProvider>;
};

export default Provider;
